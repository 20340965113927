import React from 'react';
import styles from './newWebs.module.css';

const newWebs = () => {
  return (
    <div className={styles.container}>
        <h2>NOTICIAS:</h2>
        <h3>Le invitamos a visitar nuestro <a href='http://www.saltoagro.com.ar/' target="_blank" rel="noreferrer">blog</a> para acceder a las últimas noticias relacionadas con nuestra empresa.</h3>
    </div>
  )
}

export default newWebs